<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.events')">
        <zw-events :events="events" :show-events="payload.events"></zw-events>
    </zw-sidebar>
</template>

<script>
export default {
    name: 'EventsModal',
    data() {
        return {
            payload: {},
            events: [],
        }
    },
    methods: {
        shown() {
            window.axios.get(window.apiUrl + '/events/' + this.payload.type + '/' + this.payload.id)
                .then(response => {
                    this.events = response.data.events
                })
        },
    },
}
</script>